.WelcomePage{
    height: 98vh;
    background: #252933;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Logo{
    font-size: 4rem;
    line-height: 3.5rem;
    text-align: end;
    padding: 0 0.5em 0 0;
    margin-left: -0.5em;

    color: #fff;
    font-family: "Segoe UI", sans-serif;
}

.Logo span{
    color: #d10000;
}

.Slogan{
    color: #fff;
    font-size: 1.5rem;
    margin: 0.1em 1em 0 0;

    font-style: italic;
    text-align: end;
}

.AnimationCover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;

    z-index: 0;

    background: transparent;
}

.starSky{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;

    z-index: 0;
}

.starSky div{
    height: 100%;
}

.starSky div canvas{
    transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 1000px){
    .WelcomePage{
        height: 100vh;
        background: #252933;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .AnimationCover{
        display: none;
    }

    .LogoBox{
        z-index: 10;
    }

    .Logo{
        padding: 0;
        color: #fff;
        font-family: "Segoe UI", sans-serif;
    }

    .Logo span{
        color: #d10000;
    }

    .Slogan{
        color: #fff;
        font-style: italic;
        text-align: end;
        margin: 0.1em 0 0 1em;
    }
}

@media screen and (min-width: 300px) and (min-height: 500px){
    .Logo{
        font-size: 3.5rem;
    }

    .Slogan{
        font-size: 1rem;
    }
}

@media screen and (min-width: 300px) and (min-height: 700px){
    .Logo{
        font-size: 4rem;
    }

    .Slogan{
        font-size: 1.5rem;
    }
}