.Triangle {
  /*width: 0;*/
  height: 0;
  margin: 0;
  padding: 0;
  border-left: 49vw solid transparent;
  border-right: 49vw solid transparent;

  border-top: 2vw solid var(--color-bg2);

  scroll-margin-top: 3vh;
}

.reverse {
  background: var(--color-bg2);
  border-left: 49vw solid var(--color-bg2);
  border-right: 49vw solid var(--color-bg2);
  border-top: 2vw solid var(--color-bg1);
}
