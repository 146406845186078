:root {
  --font-size-story-h1: 3rem;
  --width-carousel: 400px;
}

.index {
  background-color: var(--color-bg1);
  min-height: calc(100vh - 330px);
  overflow: hidden;
  margin: calc(50px + 2rem) 0;
}

.Wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Carousel {
  width: var(--width-carousel);
}

.sliderimg {
  height: calc(var(--width-carousel) / 1.675);
  object-fit: cover;
}

.Story {
  padding: 0 2rem;
  height: 100%;
}

.Heading * {
  display: inline;
}

.Story p,
.Story ul li {
  font-size: 1.1rem;
  line-height: 1.4em;
  margin: 1rem 0;
}

.Story a img {
  width: calc(var(--font-size-story-h1) - 0.5rem);
  height: calc(var(--font-size-story-h1) - 0.5rem);
  margin: 0 0 -7px 5px;
}

.Story h2 {
  font-size: 2rem;
}

.StoryMain h1 {
  text-decoration: underline;
  text-decoration-color: var(--color-action);
  font-size: var(--font-size-story-h1);
}

.Story p {
  margin: 1rem 0 2rem;
}

.LastSection {
  margin: 0 0 2rem 0;
}

.LastSection ul {
  margin: 1rem;
}

@media screen and (min-width: 670px) {
  :root {
    --width-carousel: 670px;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --font-size-story-h1: 3.5rem;
  }

  .Wrapper {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .Carousel {
    padding: 0 15px;
  }

  .Story {
    width: 700px;
  }
}
