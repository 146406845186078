.MyValuesPage {
  display: flex;
  flex-flow: column;
  align-items: center;

  overflow: hidden;
}

.smallValuesBox {
  display: flex;
  flex-flow: column;
  align-items: center;

  width: 90%;
  height: 90%;
  margin-top: 30px;
}

.ValuesBox {
  display: none;
}

h1 {
  font-size: 3rem;
}

@media screen and (min-width: 1000px) {
  .smallValuesBox {
    display: none;
  }

  .MyValuesPage {
    padding: 2vh 0;
    height: auto;
  }

  .MyValuesPage h1 {
    font-size: 3.5rem;
  }

  .ValuesBox {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;

    width: 90%;
    height: auto;
  }
}
