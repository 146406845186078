.ContactPage{
    display: flex;
    padding: 5vh 0;justify-content: center;
}

.ContactPage h1{
    font-size: 3rem;
    padding: 0 0 1vh;
}

@media screen and (min-width: 1000px){
    .ContactPage{
        padding: 2vh 0;
    }
}